.csku-styles.main-content {
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: inherit;
  background: #fff;
  box-sizing: border-box;
  scroll-behavior: smooth;
  padding-left: 11rem;
  margin-top: 0rem !important; }

.csku-styles a {
  color: #02C0DA;
  text-decoration: none; }

@media (max-width: 640px) {
  .csku-styles.main-content {
    padding-left: 1px !important; } }

.button-v2 {
  border: 3px solid white !important;
  border-radius: 5px !important;
  background: #02c0da !important;
  color: white !important;
  border-color: #02c0da !important;
  font-size: 1em !important;
  padding: 12px 25px !important;
  font-family: 'skufont-demibold',sans-serif !important;
  cursor: pointer !important; }

.react-datepicker-popper {
  z-index: 105; }

.table-row {
  margin-top: 5px !important;
  padding: 0.5em !important; }

.portal-row .only-hover {
  visibility: visible; }

.portal-row:hover {
  background: #f4f6f8; }
  .portal-row:hover .only-hover {
    visibility: visible; }

.table-header, .table-cell {
  padding: 1.0em 0.5em; }
  .table-header button, .table-cell button {
    padding-top: 0.2em !important;
    padding-bottom: 0.2em !important; }

.table-header {
  font-weight: bold;
  display: block; }

.option-row {
  margin: 2em 0; }

.info-block {
  background: #ffee9e;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2em 1em; }
  .info-block span {
    flex-grow: 1; }

.info-block-top-bar {
  background: #ffee9e;
  padding: 1em 1em;
  display: flex;
  flex-direction: row;
  align-items: center; }

.portal-client-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 1em;
  border-bottom: 1px solid #f0f0f0; }
  .portal-client-row span {
    flex-grow: 1; }

#hero-banner .portal-image-edit-container {
  display: inline-block;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  position: relative; }
  #hero-banner .portal-image-edit-container img {
    height: 150px; }

#hero-banner .portal-shop-actions {
  position: absolute;
  top: 0.4em;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  #hero-banner .portal-shop-actions button {
    padding: 0.4em !important;
    margin-right: 0.4em !important;
    margin-left: 0.4em; }
